<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar page="provider" />
    </div>
    <v-container class="body-container">
      <v-container class="pb-0">
        <v-row>
          <v-col cols="12">
            <h1 class="mt-10">Reservaciones</h1>
          </v-col>
          <v-col cols="12" sm="10">
            <v-card class="elevation-0">
              <v-tabs
                v-model="filterResult"
                background-color="white"
                show-arrows
              >
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab
                  v-for="status in buttonTabFilter"
                  :key="status.key"
                  @click="tabChange()"
                >
                  {{ status.name }}
                </v-tab>
              </v-tabs>
            </v-card>
          </v-col>
          <v-col cols="12" sm="2"> </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="nameFilter"
              outlined
              dense
              class="rounded-lg"
              color="primary"
              label="Nombre de cliente"
              placeholder="¿Cuál es el nombre del usuario?"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="providerFilter"
              outlined
              dense
              class="rounded-lg"
              color="primary"
              label="Nombre del proveedor"
              placeholder="¿Cuál es el nombre del usuario?"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              v-model="select"
              label="Filtrar por"
              :items="searchSelect"
              item-text="state"
              item-value="abbr"
              clearable
              class="rounded-lg"
              color="primary"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12">
            <Reservation-table
              :name-filter="nameFilter"
              :provider-filter="providerFilter"
              :filter-result="filterResult"
              :filter-status="filterStatus"
            />
            <Pagination
              :page="page"
              :arr-length="paginationLength.length"
              :per-page="perPage"
              @page-changed="updatePagination"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <Footer />
  </v-container>
</template>

<script>
import Footer from "../../components/Shared/Footer.vue";
import Navbar from "../../components/Admin/Shared/Navbar.vue";
import ReservationTable from "../../components/Admin/Reservations/ReservationTable.vue";
import Pagination from "../../components/Shared/Pagination.vue";
export default {
  components: { Footer, Navbar, ReservationTable, Pagination },
  data: () => ({
    menu: false,
    select: null,
    name: "",
    nameFilter: "",
    providerFilter: "",
    filterResult: 0,
    // Pagination data
    page: 1,
    perPage: 8,
    reservationsData: [
      {
        id: "1",
        clientName: "Nombre1",
        userName: "Nombre usuario",
        service: "Boda A&R",
        providerName: "Cliente",
        email: "invitado@invitado.com",
        status: "upcoming",
        eventDate: "00/00/0000",
      },
      {
        id: "2",
        clientName: "Nombre2",
        userName: "Nombre usuario",
        service: "Boda B&S",
        providerName: "Proveedor",
        email: "invitado@invitado.com",
        status: "canceled",
        eventDate: "00/00/0000",
      },
      {
        id: "3",
        clientName: "Nombre3",
        userName: "Nombre usuario",
        service: "Boda C&T",
        providerName: "Cliente",
        email: "invitado@invitado.com",
        status: "concluded",
        eventDate: "00/00/0000",
      },
      {
        id: "4",
        clientName: "Nombre4",
        userName: "Nombre usuario",
        service: "Boda E&M",
        providerName: "Cliente",
        email: "invitado@invitado.com",
        status: "concluded",
        eventDate: "00/00/0000",
      },
      {
        id: "5",
        clientName: "Nombre5",
        userName: "Nombre usuario",
        service: "Boda F&R",
        providerName: "Cliente",
        email: "invitado@invitado.com",
        status: "concluded",
        eventDate: "00/00/0000",
      },
      {
        id: "6",
        clientName: "Nombre6",
        userName: "Nombre usuario",
        service: "Boda G&I",
        providerName: "Proveedor",
        email: "invitado@invitado.com",
        status: "upcoming",
        eventDate: "00/00/0000",
      },
      {
        id: "7",
        clientName: "Nombre7",
        userName: "Nombre usuario",
        service: "Boda H&Z",
        providerName: "Cliente",
        email: "invitado@invitado.com",
        status: "concluded",
        eventDate: "00/00/0000",
      },
      {
        id: "8",
        clientName: "Nombre7",
        userName: "Nombre usuario",
        service: "Boda R&R",
        providerName: "Proveedor",
        email: "invitado@invitado.com",
        status: "canceled",
        eventDate: "00/00/0000",
      },
      {
        id: "9",
        clientName: "Nombre8",
        userName: "Nombre usuario",
        service: "Boda A&A",
        providerName: "Cliente",
        email: "invitado@invitado.com",
        status: "concluded",
        eventDate: "00/00/0000",
      },
      {
        id: "10",
        clientName: "Nombre9",
        userName: "Nombre usuario",
        service: "Boda O&R",
        providerName: "Proveedor",
        email: "invitado@invitado.com",
        status: "canceled",
        eventDate: "00/00/0000",
      },
      {
        id: "11",
        clientName: "Nombre1",
        userName: "Nombre usuario",
        service: "Boda A&R",
        providerName: "Cliente",
        email: "invitado@invitado.com",
        status: "upcoming",
        eventDate: "00/00/0000",
      },
    ],
    buttonTabFilter: [
      { name: "Todos", value: "all" },
      { name: "Próximas", value: "upcoming" },
      { name: "Concluidas", value: "concluded" },
      { name: "Canceladas", value: "canceled" },
    ],
    searchSelect: ["Mas reciente", "Menos reciente"],
  }),

  computed: {
    filterStatus() {
      let start = (this.page - 1) * this.perPage;
      let end = this.page * this.perPage;
      if (this.reservationsData.length < end) {
        end = this.reservationsData.length;
      }
      return this.reservationsData
        .filter((event) => {
          const eventStatus = event.status.toLowerCase();
          let buttonValue = "";
          if (this.filterResult === 1) {
            buttonValue = "upcoming";
          } else if (this.filterResult === 2) {
            buttonValue = "concluded";
          } else if (this.filterResult === 3) {
            buttonValue = "canceled";
          }
          return eventStatus.includes(buttonValue);
        })
        .slice(start, end);
    },
    paginationLength() {
      return this.reservationsData.filter((event) => {
        const eventStatus = event.status.toLowerCase();
        let buttonValue = "";
        if (this.filterResult === 1) {
          buttonValue = "upcoming";
        } else if (this.filterResult === 2) {
          buttonValue = "concluded";
        } else if (this.filterResult === 3) {
          buttonValue = "canceled";
        }
        return eventStatus.includes(buttonValue);
      });
    },
  },

  methods: {
    updatePagination(page) {
      this.page = page;
    },
    tabChange() {
      this.page = 1;
    },
  },
};
</script>

<style scoped>
.purple-gradient {
  background-color: rgba(98, 37, 130, 1);
}
</style>
