<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :filter-result="filterResult"
      :items="filterStatus"
      item-key="id"
      :header-props="{
        'sort-by-text': 'Ordenar por',
      }"
      :loading="true"
      loading-text="Cargando tabla..."
      no-results-text="No se han encontrado reservaciones"
      hide-default-footer
    >
      <v-progress-linear
        v-show="loading"
        slot="progress"
        height="3px"
        color="secondary"
        indeterminate
      ></v-progress-linear>

      <template v-slot:[`item.clientName`]="{ item }">
        <div>
          <router-link
            class="black--text"
            :to="`/admin/reservaciones/${item.clientName}&${item.service}`"
            >{{ item.clientName }}</router-link
          >
        </div>
      </template>

      <template v-slot:no-data> No hay eventos registrados. </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: {
    nameFilter: {
      type: String,
      default: "",
    },
    providerFilter: {
      type: String,
      default: "",
    },
    filterResult: {
      type: Number,
      default: 0,
    },
    filterStatus: {
      type: [String, Array],
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      singleSelect: null,
      selected: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Cliente",
          align: "start",
          sortable: false,
          value: "clientName",
          filter: (f) => {
            if (this.nameFilter) {
              return f.toLowerCase().includes(this.nameFilter.toLowerCase());
            } else {
              return f;
            }
          },
          class: "text-h5 black--text font-weight-bold",
        },
        {
          text: "Proveedor",
          value: "providerName",
          filter: (f) => {
            if (this.providerFilter) {
              return f
                .toLowerCase()
                .includes(this.providerFilter.toLowerCase());
            } else {
              return f;
            }
          },
          class: "text-h5 black--text font-weight-bold",
        },
        {
          text: "Servicio",
          value: "service",
          class: "text-h5 black--text font-weight-bold",
        },
        {
          text: "Fecha de evento",
          value: "eventDate",
          class: "text-h5 black--text font-weight-bold",
        },
        {
          text: "Correo",
          value: "email",
          class: "text-h5 black--text font-weight-bold",
        },
      ];
    },
  },
  methods: {},
};
</script>

<style scoped>
.v-data-table >>> td,
.tableFontChange {
  font-size: 18px !important;
}
</style>
